<template>
  <div>
    <div class="flex items-center mb-20">
      <div class="w-40 h-40 mr-20">
        <img v-if="url" :src="url" alt="" />
      </div>
      <div>
        <div>
          <a-space>
            <div class="text-999 text-xs" style="width: 120px">
              本次需求响应效率
            </div>
            <a-rate :value="dataSource.Response" :disabled="true"></a-rate>
          </a-space>
        </div>
        <div>
          <a-space>
            <div class="text-999 text-xs" style="width: 120px">
              平台的服务质量
            </div>
            <a-rate :value="dataSource.Service" :disabled="true"></a-rate>
          </a-space>
        </div>
      </div>
    </div>
    <div class="text-999 text-xs">改进建议</div>
    <div class="text-sm">{{ dataSource.Comment }}</div>
  </div>
</template>

<script>
import { getFileUrl } from "@/api/api/common";
export default {
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    url() {
      if (
        this.dataSource.Company.Info.Logo &&
        this.dataSource.Company.Info.Logo.ID
      ) {
        return getFileUrl(this.dataSource.Company.Info.Logo.ID);
      } else {
        return "";
      }
    },
  },
};
</script>