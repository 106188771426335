<template>
  <div class="p-20 rounded bg-white">
    <div class="relative">
      <div class="mb-20 text-left px-20">
        <a-steps :current="current">
          <a-step title="需求审核中"></a-step>
          <a-step title="匹配服务商"></a-step>
          <a-step title="评估服务商"></a-step>
          <a-step title="已完成对接"></a-step>
        </a-steps>
      </div>
      <div v-if="updateFlag">
        <ApplyForm :dataSource="detail">
          <a-space :size="20" slot-scope="{ validate }">
            <a-button @click="update(false)">取消</a-button>
            <a-button size="large" type="primary" @click="submit(validate)">
              提交
            </a-button>
          </a-space>
        </ApplyForm>
      </div>

      <div v-else>
        <ApplyDisplay
          :dataSource="detail"
          :supplys="supplys"
          :allowChecked="detail.evaluate === 1"
          @change="selectCompany"
        />
        <div class="mb-40" v-if="detail.evaluate">
          <gf-title class="mb-20">评选结果</gf-title>
          <div v-if="detail.evaluate === 1">
            <file-upload
              :multiple="false"
              :size="20"
              accept=".pdf,.docx,.doc,.png,.jpg,.jpeg"
              v-model="attachments"
            ></file-upload>
          </div>
          <div v-if="detail.evaluate === 2" class="flex items-center">
            <a-icon type="link" class="mr-10" />
            <a :href="detail.result.url" target="blank">{{
              detail.result.Name
            }}</a>
          </div>
        </div>
        <div class="mb-20">
          <gf-title class="mb-20">需求方评价</gf-title>
          <div v-if="requirementReviews.length">
            <div
              class="mb-20"
              v-for="item in requirementReviews"
              :key="item.CompanyID"
            >
              <CommentCard :dataSource="item" />
            </div>
          </div>
          <div v-else>无</div>
        </div>
        <div class="mb-20">
          <gf-title class="mb-20"
            >服务商评价<span v-if="supplyReviews.length"
              >({{ supplyReviews.length }})</span
            ></gf-title
          >
          <div v-if="supplyReviews.length">
            <div
              class="mb-20"
              v-for="item in supplyReviews"
              :key="item.CompanyID"
            >
              <CommentCard :dataSource="item" />
            </div>
          </div>
          <div v-else>无</div>
        </div>
        <div class="text-center">
          <a-space :size="20">
            <!-- <a-button
              type="primary"
              v-if="!detail.evaluate && !detail.count"
              @click="update(true)"
              >重新编辑</a-button
            > -->
            <a-button
              type="primary"
              @click="evaluateRequirement(detail.id)"
              v-if="
                detail.status === 30 && detail.count && detail.evaluate === 0
              "
              >发起评估</a-button
            >
            <a-button
              type="primary"
              @click="finishEvaluate(detail.id)"
              v-if="
                detail.status === 30 && detail.count && detail.evaluate === 1
              "
              >完成评估</a-button
            >
          </a-space>
        </div>
      </div>
    </div>
    <review-modal v-model="reviewVisible"></review-modal>
  </div>
</template>

<script>
import ApplyForm from "./components/applyForm.vue";
import ApplyDisplay from "./components/applyDisplay.vue";
import CommentCard from "./components/comment.vue";
import SelectExpertModal from "../expert/modal.vue";
import {
  getExpertRequirement,
  updateRequirement,
  closeOp,
  getRequirementSupplyList,
  evaluateRequirement,
  finishEvaluate,
  getReviewList,
} from "@/api/api/requirement";
export default {
  components: { ApplyForm, SelectExpertModal, ApplyDisplay, CommentCard },
  created() {
    const id = this.$route.params.id;
    this.id = id;
    this.getExpertRequirement(id);
    this.getReviewList(id);
  },
  data() {
    return {
      id: "",
      detail: {},
      expertSelectVisible: false,
      refuseVisible: false,
      updateFlag: false,
      comment: {},
      supplys: [],
      company: [],
      attachments: [],
      reviewVisible: false,
      reviews: [],
    };
  },
  computed: {
    current() {
      const { status, evaluate } = this.detail;
      if ([1, 10, 20].includes(status)) {
        return 0;
      } else if (status === 30 && evaluate === 0) {
        return 1;
      } else if (status === 30 && evaluate === 1) {
        return 2;
      } else if (status === 30 && evaluate === 2) {
        return 3;
      }
    },
    requirementReviews() {
      return this.reviews.filter(
        (item) => item.CompanyID === this.detail.CompanyID
      );
    },
    supplyReviews() {
      return this.reviews.filter(
        (item) => item.CompanyID !== this.detail.CompanyID
      );
    },
  },
  methods: {
    selectCompany(e) {
      let index = -1;
      this.company.forEach((item, i) => {
        if (item.id === e.id) {
          index = i;
        }
      });
      if (index === -1) {
        this.company.push(e);
      } else {
        this.company.splice(index, 1);
      }
    },
    openReview() {
      this.reviewVisible = true;
    },
    back() {
      this.$router.back();
    },
    // 修改
    update(state) {
      this.updateFlag = state;
    },
    // 更新需求
    submit(validate) {
      validate().then((res) => {
        this.updateRequirement(this.id, res);
        this.getExpertRequirement(this.id);
      });
    },
    // 获取需求接口
    getExpertRequirement(id) {
      getExpertRequirement(id)
        .then((res) => {
          this.detail = res;
          this.getRequirementSupplyList(id);
        })
        .catch(() => {
          this.$message.error("获取详情失败");
        });
    },
    // 更新需求接口
    updateRequirement(id, res) {
      updateRequirement(id, res)
        .then(() => {
          this.$message.success("更新需求成功");
        })
        .catch(() => {
          this.$message.error("更新需求失败");
        });
    },
    // 关闭需求
    close() {
      const id = this.id,
        $message = this.$message,
        back = this.back;
      this.$confirm({
        title: "是否关闭该需求",
        okText: "关闭",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          closeOp(id)
            .then(() => {
              $message.success("关闭需求成功");
              back();
            })
            .catch(() => {
              $message.error("关闭需求失败");
            });
        },
      });
    },
    // 获取需求响应列表
    getRequirementSupplyList(id) {
      getRequirementSupplyList(id)
        .then(({ rows }) => {
          this.supplys = rows;
        })
        .catch(() => {
          this.$message.error("获取需求响应列表失败");
        });
    },
    // 获取评价列表
    getReviewList(id) {
      getReviewList(id)
        .then(({ data }) => {
          this.reviews = data.rows || [];
        })
        .catch(() => {
          this.$message.error("获取评价列表失败");
        });
    },
    // 发起评估
    evaluateRequirement(id) {
      evaluateRequirement(id)
        .then(() => {
          this.$message.success("发起评估成功");
          this.getExpertRequirement(id);
        })
        .catch(() => {
          this.$message.error("发起评估失败");
        });
    },
    // 完成评估
    finishEvaluate(id) {
      if (!this.company.length) {
        return this.$message.warn("请选择意向服务商");
      }
      if (!this.attachments.length) {
        return this.$message.warn("请上传评选结果");
      }
      const temp = this.company.map((item) => item.id);
      const attach = this.attachments[0];

      finishEvaluate(id, { supplys: temp, result: attach })
        .then(() => {
          this.$message.success("评估成功");
          this.getExpertRequirement(id);
        })
        .catch(() => {
          this.$message.error("评估失败");
        });
    },
  },
};
</script>